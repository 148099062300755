<template lang="">
  <div>
    <!-- SECTION Reference Data Search -->
    <BCard
      header-class="py-1"
      body-class="pb-1"
    >
      <template #header>
        <div class="font-weight-bolder text-uppercase">
          {{ $t('utils.currencyConversion') }}
        </div>
      </template>

      <IAmOverlay :loading="loading">
        <BRow>
          <!-- ANCHOR From -->
          <BCol
            md="2"
            cols="5"
            class="mt-50 mt-lg-0"
          >
            <b-form-group
              :label="$t('utils.from')"
              label-for="from"
              class="w-100 mb-0"
            >
              <v-select
                v-model="from"
                class="w-100"
                style="font-size: 1rem;"
                :options="currencyOptions"
                label="label"
                :clearable="false"
                :reduce="val => val"
              >
                <template #option="data">
                  <span>
                    {{ data.label }}
                  </span>
                </template>

                <template #selected-option="data">
                  <span>
                    {{ data.label }}
                  </span>
                </template>
                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
            </b-form-group>
          </BCol>

          <div class="d-flex align-items-end">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              class="font-weight-bolder p-75 text-nowrap text-dark rounded-circle"
              variant="outline-dark"
              :disabled="isDisabledButtonSubmit"
              @click="handleSwitch"
            >
              <feather-icon icon="RepeatIcon" />
            </b-button>
          </div>

          <!-- ANCHOR To -->
          <BCol
            md="2"
            cols="5"
            class="mt-50 mt-lg-0"
          >
            <b-form-group
              :label="$t('utils.to')"
              label-for="to"
              class="w-100 mb-0"
            >
              <v-select
                v-model="to"
                class="w-100"
                style="font-size: 1rem;"
                :options="currencyOptions"
                label="label"
                :clearable="false"
                :reduce="val => val"
              >
                <template #option="data">
                  <span>
                    {{ data.label }}
                  </span>
                </template>

                <template #selected-option="data">
                  <span>
                    {{ data.label }}
                  </span>
                </template>
                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
            </b-form-group>
          </BCol>

          <!-- ANCHOR Amount -->
          <BCol
            md="6"
            cols="12"
            lg="3"
            class="mt-50 mt-lg-0"
          >
            <b-form-group
              :label="$t('utils.amount')"
              label-for="amount"
              class="w-100 mb-0"
            >
              <IAmInputMoney
                id="amount"
                :value-money.sync="amount"
                :placeholder="$t('utils.amountPlaceHolder')"
                :name="$t('utils.amount')"
              />
            </b-form-group>
          </BCol>

          <!-- ANCHOR - BUTTONs -->
          <BCol
            cols="12"
            md="6"
            lg="3"
            class="mt-50 mt-lg-0 d-flex-center justify-content-md-start align-items-end gap-2"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              class="font-weight-bolder p-75 text-nowrap text-success"
              variant="outline-success"
              :disabled="isDisabledButtonSubmit"
              @click="submitSearch"
            >
              <feather-icon
                v-if="!isMobileView"
                icon="RepeatIcon"
              />
              <span class="align-middle font-weight-bolder">
                {{ $t('utils.convert') }}
              </span>
            </b-button>
          </BCol>
        </BRow>

        <div
          v-if="response !== null"
          class="mt-2"
        >
          <div class="font-weight-bolder text-uppercase my-1">
            {{ $t('utils.result') }}
          </div>
          <div v-if="!response">
            {{ $t('utils.noResult') }}
          </div>
          <div v-else>
            <div
              v-for="(value, key) in response"
              :key="key"
            >
              <span>
                <span class="font-weight-bolder">{{ $te(`utils.${key}`) ? $t(`utils.${key}`) : key }}:</span> <span class="font-weight-bolder font-medium-3">{{ formatVnCurrency(value, 0, 20) }}</span>
              </span>
            </div>
          </div>
        </div>
      </IAmOverlay>
    </BCard>
    <!-- !SECTION -->
  </div>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import {
  ref, computed,
} from '@vue/composition-api'

import { apiGeneral } from '@/api'
import { currencyOptions } from '@/constants/selectOptions'

import { formatVnCurrency } from '@core/utils/filter'

import useToast from '@useToast'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    IAmInputMoney: () => import('@/components/IAmInputMoney.vue'),
    vSelect: () => import('vue-select'),
  },
  setup() {
    const { toastError } = useToast()

    // ANCHOR - DEFINE FILTERS
    const amount = ref(1)
    const from = ref('USD')
    const to = ref('VND')
    const loading = ref(false)
    const response = ref(null)
    const isDisabledButtonSubmit = computed(() => !amount.value || loading.value)

    function submitSearch() {
      if (isDisabledButtonSubmit.value) return
      response.value = null
      loading.value = true
      const payload = {
        from: from.value,
        to: to.value,
        amount: Number(amount.value),
      }
      apiGeneral.currencyConversion(payload)
        .then(res => {
          response.value = res
        })
        .catch(() => {
          toastError('Lỗi chuyển đổi')
        })
        .finally(() => {
          loading.value = false
        })
    }

    function handleSwitch() {
      const temp = from.value
      from.value = to.value
      to.value = temp
    }
    return {
      from,
      to,
      amount,
      loading,
      isDisabledButtonSubmit,
      submitSearch,
      currencyOptions,
      response,
      formatVnCurrency,
      handleSwitch,
    }
  },
}
</script>

<style lang="">

</style>
