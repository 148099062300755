var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BCard', {
    attrs: {
      "header-class": "py-1",
      "body-class": "pb-1"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "font-weight-bolder text-uppercase"
        }, [_vm._v(" " + _vm._s(_vm.$t('utils.currencyConversion')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('BRow', [_c('BCol', {
    staticClass: "mt-50 mt-lg-0",
    attrs: {
      "md": "2",
      "cols": "5"
    }
  }, [_c('b-form-group', {
    staticClass: "w-100 mb-0",
    attrs: {
      "label": _vm.$t('utils.from'),
      "label-for": "from"
    }
  }, [_c('v-select', {
    staticClass: "w-100",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "options": _vm.currencyOptions,
      "label": "label",
      "clearable": false,
      "reduce": function reduce(val) {
        return val;
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.from,
      callback: function callback($$v) {
        _vm.from = $$v;
      },
      expression: "from"
    }
  })], 1)], 1), _c('div', {
    staticClass: "d-flex align-items-end"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "font-weight-bolder p-75 text-nowrap text-dark rounded-circle",
    attrs: {
      "variant": "outline-dark",
      "disabled": _vm.isDisabledButtonSubmit
    },
    on: {
      "click": _vm.handleSwitch
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "RepeatIcon"
    }
  })], 1)], 1), _c('BCol', {
    staticClass: "mt-50 mt-lg-0",
    attrs: {
      "md": "2",
      "cols": "5"
    }
  }, [_c('b-form-group', {
    staticClass: "w-100 mb-0",
    attrs: {
      "label": _vm.$t('utils.to'),
      "label-for": "to"
    }
  }, [_c('v-select', {
    staticClass: "w-100",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "options": _vm.currencyOptions,
      "label": "label",
      "clearable": false,
      "reduce": function reduce(val) {
        return val;
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.to,
      callback: function callback($$v) {
        _vm.to = $$v;
      },
      expression: "to"
    }
  })], 1)], 1), _c('BCol', {
    staticClass: "mt-50 mt-lg-0",
    attrs: {
      "md": "6",
      "cols": "12",
      "lg": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "w-100 mb-0",
    attrs: {
      "label": _vm.$t('utils.amount'),
      "label-for": "amount"
    }
  }, [_c('IAmInputMoney', {
    attrs: {
      "id": "amount",
      "value-money": _vm.amount,
      "placeholder": _vm.$t('utils.amountPlaceHolder'),
      "name": _vm.$t('utils.amount')
    },
    on: {
      "update:valueMoney": function updateValueMoney($event) {
        _vm.amount = $event;
      },
      "update:value-money": function updateValueMoney($event) {
        _vm.amount = $event;
      }
    }
  })], 1)], 1), _c('BCol', {
    staticClass: "mt-50 mt-lg-0 d-flex-center justify-content-md-start align-items-end gap-2",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "font-weight-bolder p-75 text-nowrap text-success",
    attrs: {
      "variant": "outline-success",
      "disabled": _vm.isDisabledButtonSubmit
    },
    on: {
      "click": _vm.submitSearch
    }
  }, [!_vm.isMobileView ? _c('feather-icon', {
    attrs: {
      "icon": "RepeatIcon"
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('utils.convert')) + " ")])], 1)], 1)], 1), _vm.response !== null ? _c('div', {
    staticClass: "mt-2"
  }, [_c('div', {
    staticClass: "font-weight-bolder text-uppercase my-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('utils.result')) + " ")]), !_vm.response ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('utils.noResult')) + " ")]) : _c('div', _vm._l(_vm.response, function (value, key) {
    return _c('div', {
      key: key
    }, [_c('span', [_c('span', {
      staticClass: "font-weight-bolder"
    }, [_vm._v(_vm._s(_vm.$te("utils.".concat(key)) ? _vm.$t("utils.".concat(key)) : key) + ":")]), _vm._v(" "), _c('span', {
      staticClass: "font-weight-bolder font-medium-3"
    }, [_vm._v(_vm._s(_vm.formatVnCurrency(value, 0, 20)))])])]);
  }), 0)]) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }